import { useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { adminApi } from "../api/apiClient";
import { AdminAccount } from "../generated-api";

export const meState = atom<AdminAccount | null>({
  key: "MeStore/meState",
  default: null,
});

export const useMyInfoState = (): ((data: AdminAccount) => void) => {
  const setMyInfo = useSetRecoilState(meState);
  return useCallback(
    (data: AdminAccount) => {
      setMyInfo(data);
    },
    [setMyInfo]
  );
};

export const useMeStore = (): AdminAccount | null => useRecoilValue(meState);

export const useFetchMe = (): (() => Promise<void>) => {
  const setMe = useSetRecoilState(meState);

  return useCallback(async () => {
    const { data } = await adminApi.adminControllerMe();

    setMe(data);
  }, [setMe]);
};

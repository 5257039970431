import "./App.css";
import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import Loading from "./pages/LoadingPage";
import {
  isAuthorizedState,
  useAuthStore,
  useInitAuth,
} from "./store/useAuthStore";

const TopPage = React.lazy(() => import("./pages/TopPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const PasswordResetRequestPage = React.lazy(
  () => import("./pages/PasswordResetRequest")
);
const PasswordResetPage = React.lazy(() => import("./pages/PasswordReset"));
const AddCompanyPage = React.lazy(() => import("./pages/AddCompanyPage"));
const CompanyDetailPage = React.lazy(() => import("./pages/CompanyDetailPage"));
const CompanyManagePage = React.lazy(() => import("./pages/CompanyManagePage"));
const CompanyUsagePage = React.lazy(() => import("./pages/CompanyUsagePage"));
const EditCompanyPage = React.lazy(() => import("./pages/EditCompanyPage"));
const CompanyUsageDetailPage = React.lazy(
  () => import("./pages/CompanyUsageDetailPage")
);
const AdminManagePage = React.lazy(() => import("./pages/AdminManagePage"));

function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuthorized = useRecoilValue(isAuthorizedState);
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const [isAuthInitiated] = useAuthStore();

  const initAuth = useInitAuth();

  useEffect(() => {
    const init = async () => {
      await initAuth();
    };
    init().catch();
  }, [initAuth]);

  return !isAuthInitiated ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <TopPage />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/password_reset_request"
            element={<PasswordResetRequestPage />}
          />
          <Route path="/password_reset" element={<PasswordResetPage />} />
          <Route
            path="/company/create"
            element={
              <RequireAuth>
                <AddCompanyPage />
              </RequireAuth>
            }
          />
          <Route
            path="/company/:companyId"
            element={
              <RequireAuth>
                <CompanyDetailPage />
              </RequireAuth>
            }
          />
          <Route
            path="/company/manage/:companyId"
            element={
              <RequireAuth>
                <CompanyManagePage />
              </RequireAuth>
            }
          />
          <Route
            path="/company/usage/:companyId"
            element={
              <RequireAuth>
                <CompanyUsagePage />
              </RequireAuth>
            }
          />
          <Route
            path="/company/edit/:companyId"
            element={
              <RequireAuth>
                <EditCompanyPage />
              </RequireAuth>
            }
          />
          <Route
            path="/company/usage/:companyId/:year/:month"
            element={
              <RequireAuth>
                <CompanyUsageDetailPage />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/"
            element={
              <RequireAuth>
                <AdminManagePage />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
